import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { LocalStorageUtils } from 'src/app/shared/utils/localstorage';
import { environment } from 'src/environments/environment';
import { stringToBool } from 'src/app/shared/utils/booleansUtil';
import { NewQuotationComponent } from './new-quotation/new-quotation.component';

@Component({
  selector: 'app-quotation',
  templateUrl: './quotation.component.html',
  styleUrls: ['./quotation.component.css']
})
export class QuotationComponent implements OnInit    {
  @Output() clickMenuItem = new EventEmitter<string>();
  @ViewChild('newQuotationComponent') newQuotationComponent: NewQuotationComponent | undefined;

  canAccess = false;
  public LocalStorage = new LocalStorageUtils();
  headerTitle!: string;

  constructor() {
    this.setCanAccess();
  }

  ngOnInit   (): void {
    this.clickMenuItem.emit('quotation');
  }

  setCanAccess(){
    var isSalesRepresentative = this.LocalStorage.searchGroup(environment.SALES_REPRESENTATIVE_GROUP_ID);
    var isSalesRepresentativeAdmin = this.LocalStorage.searchGroup(environment.SALES_REPRESENTATIVE_ADMIN_GROUP_ID);
    var isExternal = this.LocalStorage.searchGroup(environment.EXTERNAL_USERS_GROUP_ID);
    var isProduction = stringToBool(environment.IS_PRODUCTION)

    this.canAccess = isSalesRepresentative || isSalesRepresentativeAdmin || (isExternal && !isProduction);
  }

  setHeaderTitle(newTitle: string) {
    this.headerTitle = newTitle;
  }

  downloadReport() {
    if(!this.newQuotationComponent){
      return;
    }

    this.newQuotationComponent.clickExportQuotationToCsv();
  }
}
